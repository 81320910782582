import Link from 'next/link';
import { FC, ReactNode } from 'react';

import { Image } from 'src/general/components/Image/Image';
import { formatNumber } from 'src/general/helpers/formatNumber';
import { BaseCarData } from 'src/types/Car.types';

import { Button } from '../Button/Button';
import { Countdown } from '../Countdown/Countdown';
import styles from './PurchaseInformation.module.scss';

export const enum PURCHASE_INFORMATION_STATUS {
  IN_PROGRESS = 'in_progress',
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'not_available',
}

interface Props {
  status: PURCHASE_INFORMATION_STATUS;
  carData: BaseCarData | null;
  cartExpiry: Date | null;
}

const content: {
  [key in PURCHASE_INFORMATION_STATUS]: {
    title: ReactNode;
    cta: ReactNode;
  };
} = {
  [PURCHASE_INFORMATION_STATUS.IN_PROGRESS]: {
    title: 'Purchase in progress',
    cta: 'Continue purchase',
  },
  [PURCHASE_INFORMATION_STATUS.AVAILABLE]: {
    title: 'Car is still available',
    cta: 'Buy now',
  },
  [PURCHASE_INFORMATION_STATUS.NOT_AVAILABLE]: {
    title: 'Car is no longer available',
    cta: 'View similar cars',
  },
};

/**
 * Display information about the current purchase
 * Used for example in the header menu
 */
export const PurchaseInformation: FC<Props> = ({ status, carData, cartExpiry }) => {
  if (!carData) {
    return null;
  }
  const { title, cta } = content[status];

  const ctaHref =
    status === PURCHASE_INFORMATION_STATUS.NOT_AVAILABLE
      ? `/product-detail/${carData.sku}/similar`
      : `/checkout?sku=${carData.sku}`;

  return (
    <section className={styles.root}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.car}>
        <Link href={`/product-detail/${carData.sku}`} className={styles.carLink}>
          <span className="sr-only">{carData.model}</span>
        </Link>
        <div className={styles.carImage}>
          <Image src={carData.imageUrl} width={120} height={68} alt={carData.model} />
        </div>
        <h4 className={styles.carTitle}>{carData.model}</h4>
        <p className={styles.carSubtitle}>{carData.variant}</p>
      </div>
      <footer className={styles.footer}>
        <div className={styles.footerInfo}>
          {status === PURCHASE_INFORMATION_STATUS.IN_PROGRESS && cartExpiry ? (
            <>
              <span className={styles.footerInfoLabel}>Time remaining</span>
              <Countdown countdown={cartExpiry} />
            </>
          ) : (
            <span className={styles.footerInfoPrice}>{formatNumber(carData.price, { prepend: '$' })}</span>
          )}
        </div>
        <Button href={ctaHref} data-testid="Action-button" size="small">
          {cta}
        </Button>
      </footer>
    </section>
  );
};
