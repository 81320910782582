const HOURS_IN_MILLISECONDS = 60 * 60 * 1000;
const MINUTES_IN_MILLISECONDS = 60 * 1000;
const SECONDS_IN_MILLISECONDS = 1000;

export const getTimeDifference = (countdown: Date | null) => {
  if (countdown !== null) {
    return countdown.getTime() - new Date().getTime();
  }
  return null;
};

export const formatTimeDifference = (difference: number | null) => {
  if (difference === null || difference <= 0) {
    return '0:00:00';
  }
  const hours = Math.floor(difference / HOURS_IN_MILLISECONDS);
  const minutes = Math.floor((difference - hours * HOURS_IN_MILLISECONDS) / MINUTES_IN_MILLISECONDS);
  const seconds = Math.floor(
    (difference - hours * HOURS_IN_MILLISECONDS - minutes * MINUTES_IN_MILLISECONDS) / SECONDS_IN_MILLISECONDS,
  );

  const result = [hours.toString(), minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')];

  return result.join(':');
};
