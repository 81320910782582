import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconClock: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path d="M12.6 11.679V7.4h-1.2v4.921l3.766 2.511.666-.998-3.232-2.155Z" />
    <path
      fillRule="evenodd"
      d="M20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-1.2 0a6.8 6.8 0 1 1-13.6 0 6.8 6.8 0 0 1 13.6 0Z"
      clipRule="evenodd"
    />
  </Icon>
);
