import classNames from 'classnames';
import { FC, useState } from 'react';

import { IconClock } from 'src/general/Icons/IconClock';

import { useInterval } from 'usehooks-ts';
import { formatTimeDifference, getTimeDifference } from './Countdown.helpers';
import styles from './Countdown.module.scss';

export interface CountDownProps {
  countdown: Date | null;
}

export const Countdown: FC<CountDownProps> = ({ countdown }) => {
  const [timeLeft, setTimeLeft] = useState<string>(formatTimeDifference(getTimeDifference(countdown)));

  useInterval(() => {
    const diff = getTimeDifference(countdown);
    if (diff === null || diff <= 0) {
      setTimeLeft('0:00:00');
    } else {
      setTimeLeft(formatTimeDifference(diff));
    }
  }, 1000);

  return (
    <section className={classNames(styles.root, { [styles.unavailable]: countdown === null })}>
      <div className={styles.imageWrapper}>
        <IconClock />
      </div>
      <div className={styles.countdown} data-testid="COUNTDOWN_TIME_LEFT">
        {timeLeft}
      </div>
    </section>
  );
};
