'use client';

import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { FC, ReactNode } from 'react';

import { IconHeart } from 'src/general/Icons/IconHeart';

import { ButtonIcon } from '../Button/ButtonIcon';
import { AccountPopper } from './AccountPopper/AccountPopper';
import styles from './HeaderMenu.module.scss';
import { HeaderMenuData } from './HeaderMenu.type';
import { HeaderMenuLogo } from './HeaderMenuLogo';

interface Props {
  keywordSearch: ReactNode;
  headerData: HeaderMenuData;
}

const SearchWidget = dynamic(() => import('./SearchWidget/SearchWidget').then((mod) => mod.SearchWidget), {
  ssr: false,
});

const HeaderMenuLinks = dynamic(() => import('./HeaderMenuLinks').then((mod) => mod.HeaderMenuLinks), {
  ssr: false,
});

const HeaderMenuLinksTrack = dynamic(() => import('./HeaderMenuLinksTrack').then((mod) => mod.HeaderMenuLinksTrack), {
  ssr: false,
});

export const HeaderMenu: FC<Props> = ({ keywordSearch, headerData }) => {
  return (
    <>
      <nav className={classNames(styles.root, 'HeaderMenu')} data-testid="header-menu" key="header-menu">
        <div className={styles.container}>
          {/* Logo */}
          <Link href="/" className={styles.logo} prefetch={false}>
            <HeaderMenuLogo />
          </Link>
          {/* Links */}
          <HeaderMenuLinks headerData={headerData} />
          {/* Controls */}
          <div className={styles.controls}>
            <SearchWidget className={styles.searchTrigger} keywordSearch={keywordSearch} />
            <ButtonIcon
              Icon={IconHeart}
              href="/watchlist"
              className={styles.watchlist}
              title="Watchlist"
              variant="tertiary"
              styling="round"
              size="small"
              prefetch={false}
            />
            <AccountPopper headerData={headerData} />
          </div>
          {/* Menu links track */}
          <HeaderMenuLinksTrack />
        </div>
      </nav>
      {/* Spacer element same height as the header bar to avoid overlapping of fixed header bar with rest of content */}
      <div className={styles.verticalSpacer} />
    </>
  );
};
